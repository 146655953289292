// body: JSON.stringify({
//     uname: 'zhangsan',
//     pwd: '456'
// }),
// headers: {
//     'Content-Type': 'application/json'
// }

const obj = {
  setMisc(tpl, text) {
    return fetch(`/admin-api/set-misc`, {
      method: "POST",
      body: JSON.stringify({
        tpl,
        text,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        return data.json();
      })
      .then((data) => data.data);
  },
  getMisc(tpl) {
    return fetch(`/admin-api/get-misc`, {
      method: "POST",
      body: JSON.stringify({
        tpl,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        return data.json();
      })
      .then((data) => data.data);
  },
};

export default obj;
