import { MessageBox } from 'element-ui';
/**
 使用方法:
 import { confirmation } from '@/utils/decorator';
 @confirmation('确定执行？')
 foo() {
        // ....
    }
 */
export function confirmation(message) {
    return function(target, name, descriptor) {
        const oldValue = descriptor.value;
        descriptor.value = function(...args) {
            const msg = typeof message === 'function' ? message(...args) : message;
            MessageBox.confirm(msg, '提示', { type: 'warning' })
                .then(oldValue.bind(this, ...args))
                .catch(() => {});
        };
        return descriptor;
    }
}
// 简单实现函数记忆化 防止重复调用接口
export function memoize(target, name, descriptor) {
    let oldValue = descriptor.value; // 源函数
    let cache = {};
    descriptor.value = async function(...args) {
        let address = args.join();
        if (cache[address] == null) cache[address] = await oldValue.apply(this, args);
        return cache[address];
    };
    return descriptor;
}
