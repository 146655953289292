<template>
  <div>
    <el-select v-model="tpl" placeholder="请选择" @change="getData">
      <el-option
        v-for="item in selectList"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
    <el-divider></el-divider>
    <el-input
      type="textarea"
      :rows="20"
      placeholder="请输入内容"
      v-model="text"
    >
    </el-input>
    <el-divider></el-divider>
    <el-button type="primary" @click="save">保存</el-button>
  </div>
</template>

<script>
import F from "./fetch";
import { confirmation } from "@/utils/decorator";

export default {
  name: "ResourceList",
  components: {},
  data() {
    return {
      tpl: "avatar",
      text: "",
      selectList: [
        {
          label: "avatar",
          value: "avatar",
        },
        {
          label: "promote",
          value: "promote",
        },
      ],
    };
  },
  async created() {},
  mounted() {
    this.getData();
  },
  methods: {
    save() {
      F.setMisc(this.tpl, this.text).then((data) => {
        this.$message.success("成功");
      });
    },

    getData() {
      F.getMisc(this.tpl).then((data) => {
        this.text = data.text;
      });
    },
  },
};
</script>

<style scoped>
.title {
  display: flex;
}
.left {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #fff;
  padding: 20px;
  flex: 1;
}
.right {
  width: 300px;
  padding: 2px 20px;
  display: flex;

  align-items: flex-start;
  justify-content: flex-end;
}
</style>
